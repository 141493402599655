import React, { Component, useState, useEffect } from "react";
import { Row } from "reactstrap";

//Import Blog Box
import CounterBox from "./counter-box";

function Counter(props) {
  const [counters, setCounters] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://betterblocks.landmarkventures.com:7770/api/stats/platform"
        );
        const { allUsers, allBlocks, allCollections, allTweets } =
          await response.json();
        if (!allUsers) {
          throw "Error retrieving stats from backend";
        }
        const counters = [
          { icon: "user-plus", title: "Unique Users", start: 0, end: allUsers },
          { icon: "smile", title: "Bots Created", start: 0, end: allBlocks },
          {
            icon: "twitter",
            title: "Automated Tweets Posted",
            start: 0,
            end: allTweets,
          },
          {
            icon: "layout",
            title: "Collections Made Better",
            start: 0,
            end: allCollections,
          },
        ];
        setCounters(counters);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  if (counters.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Row id="counter">
        <CounterBox counters={counters} />
      </Row>
    </React.Fragment>
  );
}

export default Counter;
