import axios from "axios";
import { toast } from "react-hot-toast";

// const API_URL = "https://localhost:7770/api/";
const API_URL = "https://betterblocks.landmarkventures.com:7770/api/";

class ApiService {
  constructor() {}

  async get(endpoint) {
    try {
      const response = await axios.get(`${API_URL}${endpoint}`, {
        headers: {
          crossdomain: true,
        },
      });
      // handle successful response
      return response.data;
    } catch (error) {
      if (error.response) {
        // handle server response with status code outside the range of 2xx
        console.log(error.response.status);
        console.log(error.response.data);
        return toast.error(`Server error`);
      } else if (error.request) {
        // handle server error without a response
        console.log(error.request);
        return toast.error(`Server error`);
      } else {
        // handle client error
        console.log("Error", error.message);
        return toast.error(`Server error`);
      }
    }
  }

  async post(endpoint, data) {
    // console.log(`Bearer ${this.token}`);
    try {
      const response = await axios.post(`${API_URL}${endpoint}`, data, {
        headers: {
          crossdomain: true,
        },
      });
      // handle successful response
      return response.data;
    } catch (error) {
      if (error.response) {
        // handle server response with status code outside the range of 2xx
        console.log(error.response.status);
        console.log(error.response.data);
        return toast.error(`Server error`);
      } else if (error.request) {
        // handle server error without a response
        console.log(error.request);
        return toast.error(`Server error`);
      } else {
        // handle client error
        console.log("Error", error.message);
        return toast.error(`Server error`);
      }
    }
  }
}

export default ApiService;
